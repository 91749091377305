import * as React from "react";
import { useLocation } from "@reach/router";

import { SEO } from "../components/seo";
import Page from "../components/Page/Page";
import TopBar from "../components/TopBar/TopBar";
import Section from "../components/Section/Section";
import SectionHeading from "../components/SectionHeading/SectionHeading";
import Footer from "../components/Footer/Footer";

import "./LegalPage.css";

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/GlobalContextProvider";

const CodeOfEthicsPage = ({ pageContext }) => {
  const dispatch = React.useContext(GlobalDispatchContext);

  return (
    <Page>
      <TopBar showSearch={true} hideCurrency={true} />
      <div style={{ maxWidth: 1000, margin: "0 auto" }}>
        <Section>
          <SectionHeading>Code of Ethics | AI Product Reviews</SectionHeading>
          <div style={{ maxWidth: 900 }}>
            <p>
              All participants of ai productreviews are expected to abide by our
              Code of Conduct, both online and during in-person events that are
              hosted and/or associated with ai productreviews.
            </p>
            <h2>The Pledge</h2>
            <p>
              In the interest of fostering an open and welcoming environment, we
              pledge to make participation in our project and our community a
              harassment-free experience for everyone, regardless of age, body
              size, disability, ethnicity, gender identity and expression, level
              of experience, nationality, personal appearance, race, religion,
              or sexual identity and orientation.
            </p>
            <h2>The Standards</h2>
            <p>
              Examples of behaviour that contributes to creating a positive
              environment include:
            </p>
            <ul>
              <li>Using welcoming and inclusive language</li>
              <li>Being respectful of differing viewpoints and experiences</li>
              <li>Gracefully accepting constructive criticism</li>
              <li>
                Referring to people by their preferred pronouns and using
                gender-neutral pronouns when uncertain
              </li>
            </ul>
            <p>Examples of unacceptable behaviour by participants include:</p>
            <ul>
              <li>
                Trolling, insulting/derogatory comments, public or private
                harassment
              </li>
              <li>
                Publishing others' private information, such as a physical or
                electronic address, without explicit permission
              </li>
              <li>
                Not being respectful to reasonable communication boundaries,
                such as 'leave me alone,' 'go away,' or 'I&rsquo;m not
                discussing this with you.'
              </li>
              <li>
                The usage of sexualised language or imagery and unwelcome sexual
                attention or advances
              </li>
              <li>Swearing, usage of strong or disturbing language</li>
              <li>
                Demonstrating the graphics or any other content you know may be
                considered disturbing
              </li>
              <li>
                Starting and/or participating in arguments related to politics
              </li>
              <li>
                Assuming or promoting any kind of inequality including but not
                limited to: age, body size, disability, ethnicity, gender
                identity and expression, nationality and race, personal
                appearance, religion, or sexual identity and orientation
              </li>
              <li>Drug promotion of any kind</li>
              <li>Attacking personal tastes</li>
              <li>
                Other conduct which you know could reasonably be considered
                inappropriate in a professional setting.
              </li>
            </ul>
            <h2>Enforcement</h2>
            <p>
              Violations of the Code of Conduct may be reported by sending an
              email to helllo@ai-productreviews.com. All reports will be
              reviewed and investigated and will result in a response that is
              deemed necessary and appropriate to the circumstances. Further
              details of specific enforcement policies may be posted separately.
            </p>
            <p>
              We hold the right and responsibility to remove comments or other
              contributions that are not aligned to this Code of Conduct, or to
              ban temporarily or permanently any members for other behaviours
              that they deem inappropriate, threatening, offensive, or harmful.
            </p>
          </div>
        </Section>
      </div>
      <Footer />
    </Page>
  );
};

export default CodeOfEthicsPage;

export const Head = () => {
  const location = useLocation();
  return (
    <SEO
      index
      follow
      title={"Code of Ethics | AI Product Reviews"}
      description={
        "Our dedication to ethical AI practices. Discover our modern slave statement and responsible AI commitment."
      }
      pathname={location.pathname}
    />
  );
};
